import React, { useState, useEffect } from "react";
import plusWhite from "../../assets/images/plus-white.svg";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../styles/style.css";
import "../../styles/payout.css";
import "../../styles/upi-mandate.css";
import editIcon from "../../assets/images/edit_btn.svg";
import { TabPanel, Tabs } from "react-tabs";
import { toast } from "react-hot-toast";
import trashIcon from "../../assets/images/trash-03.svg";
import Button from "../../components/bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useFormik } from "formik";
import Pagination from "../../customComponents/pagination/paginationView";
import {
  addCategoryData,
  deleteCategoryData,
  getCategoryData,
  getParentId,
  getProductList,
} from "../../action/category";
import Select from "../../components/bootstrap/forms/Select";
import Option from "../../components/bootstrap/Option";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import { Loader } from "../loader";
import { Modal } from "react-bootstrap";
import { SingleErrorMessage } from "../singleErrorMessage";

const CategoryListPage = () => {
  const [categoryList, setCategoryList] = useState([]);
  const [parentData, setParentData] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [totalData, setTotalData] = useState(null);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");

  const [languages] = useState([
    { name: "English", shortCode: "en" },
    { name: "Hindi", shortCode: "hi" },
  ]);

  const getCategoryPage = async (page) => {
    try {
      setIsLoading(true);
      const response = await getProductList(page, pageSize); // Adjust 10 to the desired number of items per page
      if (response.status) {
        setCategoryList(response.data.list);
        setTotalData(response.data.count);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Failed to fetch category data", error);
    }
  };

  useEffect(() => {
    getCategoryPage(pageNo);
  }, [pageNo, pageSize]);

  const handleClose = () => {
    setShow(false);
    addCategoryFormik.handleReset();
  };
  const handleShow = () => setShow(true);

  const addCategoryFormik = useFormik({
    initialValues: {
      name: { en: "" },
      parentId: "",
    },
    onSubmit: async (values, { resetForm }) => {
      console.log("values", values);
      setIsLoading(true);
      try {
        const res = await addCategoryData(values);
        if (res.status) {
          fetchParentId();
          resetForm();
          setShow(false);
          // showNotification("Category", res.message, "success");
          getCategoryPage(1);
          handleClose();
          setIsLoading(false);
        } else {
          // showNotification("Category", res.message, "danger");
        }
      } catch (error) {
        console.error("Failed to add category", error);
      }
    },
  });
  const fetchParentId = async () => {
    const res = await getParentId();
    if (res.status) {
      setParentData(res.data);
    }
  };

  useEffect(() => {
    fetchParentId();
  }, []);

  const handleLanguageChange = (event, index) => {
    const selectedLanguage = event.target.value;
    const updatedNames = { ...addCategoryFormik.values.name };
    const currentLanguageKey = Object.keys(updatedNames)[index];
    updatedNames[selectedLanguage] = updatedNames[currentLanguageKey] || "";
    delete updatedNames[currentLanguageKey];
    addCategoryFormik.setFieldValue("name", updatedNames);
  };

  const handleAddMore = () => {
    const updatedNames = { ...addCategoryFormik.values.name };
    const newKey = languages.find(
      (lang) => !Object.keys(updatedNames).includes(lang.shortCode)
    )?.shortCode;
    if (newKey) {
      updatedNames[newKey] = "";
      addCategoryFormik.setFieldValue("name", updatedNames);
    }
  };

  const handleDeleteLanguage = (languageKey) => {
    const updatedNames = { ...addCategoryFormik.values.name };
    delete updatedNames[languageKey]; // Remove the selected language record
    addCategoryFormik.setFieldValue("name", updatedNames); // Update the formik state
  };

  const handleDelete = async () => {
    try {
      const res = await deleteCategoryData(selectedItem);
      if (res.status) {
        toast.custom(
          <SingleErrorMessage
            data={res.message}
            error={false}
            bColor="#175FC9"
          />
        );
        getCategoryPage(pageNo);
        setShowDeleteModel(false);
      }
    } catch (error) {
      //console.error("Failed to delete category", error);
    }
  };
  const availableLanguages = (currentValue) => {
    return languages.filter(
      (language) =>
        !Object.keys(addCategoryFormik.values.name).includes(
          language.shortCode
        ) || language.shortCode === currentValue
    );
  };

  const [categoryType, setCategoryType] = useState("");
  const [mainCategories, setMainCategories] = useState([]);
  useEffect(() => {
    fetchMainCategories();
  }, [show]);
  const fetchMainCategories = () => {
    const mainCategoryList = parentData.filter((item) => item.isMainCategory);
    setMainCategories(mainCategoryList);
  };
  const handleCategoryTypeChange = (e) => {
    setCategoryType(e.target.value);
    addCategoryFormik.setFieldValue("parentId", "");
  };
  return (
    <div className="payout_data-content">
      <div className="payout_data-head">
        <div className="payout_data-head-top mb-2">
          <div className="payout_data-left">
            <div className="payout_data_ttl">
              {" "}
              <Breadcrumb paths={[{ name: "Products" }]} />
            </div>
          </div>
          <Button color="info" tag="a" onClick={handleShow}>
            Create New
            <img src={plusWhite} alt="" className="payout_down" />
          </Button>
        </div>
      </div>
      <div className="tab_box">
        <Accordion defaultActiveKey="0" className="custom_accordion">
          {isLoading ? (
            <tr>
              <td colSpan="4">
                <Loader />
              </td>
            </tr>
          ) : (
            <>
              {categoryList?.length > 0 ? (
                categoryList?.map((item) => (
                  <Accordion.Item eventKey={item._id}>
                    <div className="accordion_item_content">
                      <Accordion.Header>{item?.name?.en}</Accordion.Header>
                      <div className="flex_btn">
                        <img src={editIcon} />
                        <img src={trashIcon} />
                      </div>
                    </div>
                    {/* {item?.products.length > 0 ? ( */}
                    <Accordion.Body>
                      <table className="payout_table upi-mandate_table">
                        <thead>
                          <tr className="tableHead">
                            <th className="thHeader">
                              <div className="payoutHead hndIcon">Name</div>
                            </th>
                            <th className="thHeader">
                              <div className="payoutHead hndIcon">Action</div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {item?.products?.length > 0 ? (
                            item?.products?.map((product) => {
                              return (
                                <tr key={product?._id}>
                                  <td>{product?.name?.en}</td>
                                  <td className="flex_btn">
                                    <img src={editIcon} />
                                    <img src={trashIcon} />
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="6" className="text-center">
                                No data available
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </Accordion.Body>
                    {/* ) : (
                      ""
                    )} */}
                  </Accordion.Item>
                ))
              ) : (
                <tr>
                  <td colSpan="2" className="text-center">
                    No data available
                  </td>
                </tr>
              )}
            </>
          )}
          {/* <Accordion.Item eventKey="1">
            <div className="accordion_item_content">
              <Accordion.Header>Fruits</Accordion.Header>
              <div className="flex_btn">
                <img src={editIcon} />
                <img src={trashIcon} />
              </div>
            </div>
            <Accordion.Body>
              <table className="payout_table upi-mandate_table">
                <thead>
                  <tr className="tableHead">
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Name</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Action</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Apple</td>
                    <td className="flex_btn">
                      <img src={editIcon} />
                      <img src={trashIcon} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </Accordion.Body>
          </Accordion.Item> */}
        </Accordion>

        {categoryList.length > 0 && (
          <>
            <Pagination
              currentPage={pageNo}
              totalCount={totalData}
              pageSize={pageSize}
              onPageChange={(page) => setPageNo(page)}
              changePageSize={(page) => {
                setPageSize(page);
                setPageNo(1);
              }}
            />
          </>
        )}

        <Offcanvas show={show} onHide={handleClose} placement="end">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Add New Product</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="add_buyer_content">
              <div className="input-label">Category Type</div>
              <Select
                className="custom_dropdn"
                name="categoryType"
                value={categoryType}
                onChange={handleCategoryTypeChange}
              >
                <Option value="">Select Category Type</Option>
                <Option value="main">Main Category</Option>
                <Option value="sub">Sub Category</Option>
              </Select>

              {categoryType === "main" && (
                <form onSubmit={addCategoryFormik.handleSubmit}>
                  <div className="input-box">
                    <div className="input-label">Category Name</div>
                    <div className="input_field">
                      <input
                        type="text"
                        className="input"
                        name={`name.en`}
                        placeholder="Enter Category Name"
                        value={addCategoryFormik.values.name.en || ""}
                        onChange={addCategoryFormik.handleChange}
                      />
                    </div>
                  </div>
                  <div className="addBuyer_submit-btn">
                    <button
                      className="btn btn-success"
                      type="button"
                      onClick={handleAddMore}
                    >
                      Add More
                    </button>
                    <button className="btn btn-success" type="submit">
                      Save
                    </button>
                  </div>
                </form>
              )}

              {categoryType === "sub" && (
                <form onSubmit={addCategoryFormik.handleSubmit}>
                  <div className="input-box">
                    <div className="input-label">Select Main Category</div>
                    <Select
                      className="custom_dropdn"
                      name="parentId"
                      value={addCategoryFormik.values.parentId}
                      onChange={addCategoryFormik.handleChange}
                    >
                      <Option value="">Select a main category</Option>
                      {parentData.map((item) => (
                        <Option key={item._id} value={item._id}>
                          {item.name.en}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div className="input-box">
                    <div className="input-label">Category Name</div>
                    <div className="input_field">
                      <input
                        type="text"
                        className="input"
                        name={`name.en`}
                        placeholder="Enter Category Name"
                        value={addCategoryFormik.values.name.en || ""}
                        onChange={addCategoryFormik.handleChange}
                      />
                    </div>
                  </div>
                  <div className="addBuyer_submit-btn">
                    {/* <button
                      className="btn btn-success"
                      type="button"
                      onClick={handleAddMore}
                    >
                      Add More
                    </button> */}
                    <button className="btn btn-success" type="submit">
                      Save
                    </button>
                  </div>
                </form>
              )}
            </div>
          </Offcanvas.Body>
        </Offcanvas>

        <Modal
          show={showDeleteModel}
          onHide={() => {
            setShowDeleteModel(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Product</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this product?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setShowDeleteModel(false);
              }}
            >
              Close
            </Button>
            <Button
              className="btn-dark"
              variant="primary"
              onClick={() => {
                handleDelete();
              }}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default CategoryListPage;
