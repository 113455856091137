import { requestWithToken } from "../helper/helper";

const fetchStates = async () => {
  try {
    const response = await requestWithToken("GET", `/location/states`);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchDistricts = async (id) => {
  try {
    const response = await requestWithToken("GET", `/location/districts/${id}`);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchSubDistricts = async (id) => {
  try {
    const response = await requestWithToken("GET", `/location/blocks/${id}`);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchGaons = async (id) => {
  try {
    const response = await requestWithToken("GET", `/location/gaons/${id}`);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export { fetchStates, fetchDistricts, fetchSubDistricts, fetchGaons };
