import React from "react";
import Donut from "../../components/Graph/donutChart";
import BarChart from "../../components/Graph/barChart";

export const Dashboard = () => {
  return (
    <>
      <div className="payout_data-content mb-5">
        <div className="row">
          <div className="col-lg-6">
            <Donut />
          </div>
          <div className="col-lg-6">
            <BarChart />
          </div>
          <div className="col-lg-6">
            <BarChart />
          </div>
        </div>
      </div>
    </>
  );
};
