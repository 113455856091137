import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { editGaon, fetchGaonDetails } from "../../action/gaonApi";
import { useNavigate, useParams } from "react-router-dom";
import ThumbnailPicker from "../../components/ThumbnailPicker/ThumbnailPicker";
import uploadImage from "../../action/imageUpload";

import Icon from "../../components/icon/Icon";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import { Loader } from "../loader";
// import Breadcrumb from "../../components/bootstrap/Breadcrumb";

const EditeGaon = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const editGaonFormik = useFormik({
    initialValues: {
      info: "",
      name: "",
      pic: [],
      extras: {},
      demography: {},
      geographicalInfo: {},
    },
    onSubmit: async (values) => {
      setIsLoading(true);
      const response = await editGaon(id, values);
      if (response.status) {
        setIsLoading(false);
        //   showNotification("Gaon", response.message, "success");
        navigate("/gaon");
      } else {
        //   showNotification("Gaon", response.message, "danger");
      }
    },
  });
  const handleUpdateGaon = async () => {
    const response = await fetchGaonDetails(id);
    if (response.status) {
      editGaonFormik.setFieldValue("name", response.data?.name ?? "");
      editGaonFormik.setFieldValue("info", response.data?.info ?? "");
      editGaonFormik.setFieldValue("pic", response.data?.pic ?? "");
      editGaonFormik.setFieldValue("extras", response.data?.extras ?? "");
      editGaonFormik.setFieldValue(
        "demography",
        response.data?.demography ?? ""
      );
      editGaonFormik.setFieldValue(
        "geographicalInfo",
        response.data?.geographicalInfo ?? ""
      );
    } else {
      // showNotification("Country", response.message, "danger");
    }
  };
  const uploadMediaImage = async (file, index) => {
    const formData = new FormData();
    formData.append("file", file);
    const res = await uploadImage(formData, "gaon");
    if (res.status) {
      editGaonFormik.setFieldValue(`pic[${index}]`, res.data[0]);
    }
  };

  useEffect(() => {
    handleUpdateGaon();
  }, [id]);

  const handelDeleteOption = (index) => {
    editGaonFormik.setFieldValue(
      "pic",
      editGaonFormik.values.pic.filter((value, ind) => {
        return ind !== index;
      })
    );
  };

  const handleAddMoreOption = () => {
    editGaonFormik.setFieldValue("pic", [...editGaonFormik.values.pic, ""]);
  };
  return (
    <div className="payout_data-content">
      <div className="payout_data-head">
        <div className="payout_data-head-top">
          <div className="payout_data-left">
            <div className="payout_data_ttl">
              <Breadcrumb
                paths={[
                  { name: "Gaon", link: "/gaon" },
                  { name: "Edit Gaon", link: "/gaon/edit" },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
      {isLoading && <Loader />}
      <div className="tab_box">
        <form onSubmit={editGaonFormik.handleSubmit} className="m-4">
          <div className="row">
            <div className="col-lg-6">
              <div className="input-box">
                <div className="input-label">Gaon Name</div>
                <div className="input_field">
                  <input
                    type="text"
                    className="input"
                    name="name"
                    disabled
                    placeholder="Enter gaon name"
                    value={editGaonFormik.values.name}
                  />
                </div>
              </div>
              <div className="input-box">
                <div className="input-label">Longitude</div>
                <div className="input_field">
                  <input
                    disabled
                    className="input"
                    type="text"
                    placeholder="Longitude"
                    rows={7}
                    name="extras"
                    value={editGaonFormik.values.extras.lng}
                    onChange={(e) => {
                      editGaonFormik.setFieldValue(
                        "extras.lng",
                        Number(e.target.value)
                      );
                    }}
                  />
                </div>
              </div>
              <div className="input-box">
                <div className="input-label">District</div>
                <div className="input_field">
                  <input
                    disabled
                    className="input"
                    type="text"
                    placeholder="Enter district"
                    name="district"
                    value={editGaonFormik.values.district}
                    onChange={(e) => {
                      editGaonFormik.setFieldValue("district", e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="input-box">
                <div className="input-label">Sea Level Range</div>
                <div className="input_field">
                  <input
                    type="text"
                    className="input"
                    name="Mountainrange"
                    placeholder="Enter Mountainrange"
                    value={
                      editGaonFormik.values?.geographicalInfo?.mountainrange
                    }
                    onChange={(e) => {
                      editGaonFormik.setFieldValue(
                        "geographicalInfo.mountainrange",
                        e.target.value
                      );
                    }}
                  />
                </div>
              </div>
              <div className="input-box">
                <div className="input-label">Population</div>
                <div className="input_field">
                  <input
                    type="text"
                    className="input"
                    name="population"
                    placeholder="Enter Population"
                    value={editGaonFormik.values?.demography?.population}
                    onChange={(e) => {
                      editGaonFormik.setFieldValue(
                        "demography.population",
                        e.target.value
                      );
                    }}
                  />
                </div>
              </div>
              <div className="input-box">
                <div className="input-label">Forest Area</div>
                <div className="input_field">
                  <input
                    type="text"
                    className="input"
                    name="forestarea"
                    placeholder="Enter ForestArea"
                    value={editGaonFormik.values?.geographicalInfo?.forestarea}
                    onChange={(e) => {
                      editGaonFormik.setFieldValue(
                        "geographicalInfo.forestarea",
                        e.target.value
                      );
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="input-box">
                  <div className="input-label">Language</div>
                  <div className="input_field">
                    <input
                      type="text"
                      className="input"
                      name="langauge"
                      placeholder="Enter langauge"
                      value={editGaonFormik.values?.demography?.langauge}
                      onChange={(e) => {
                        editGaonFormik.setFieldValue(
                          "demography.langauge",
                          e.target.value
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="input-box">
                <div className="input-label">Zone</div>
                <div className="input_field">
                  <input
                    type="text"
                    className="input"
                    placeholder="Enter Zone"
                    name="zone"
                    value={editGaonFormik?.values?.geographicalInfo?.zone}
                    onChange={(e) => {
                      editGaonFormik.setFieldValue(
                        "geographicalInfo.zone",
                        e.target.value
                      );
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="input-box">
                <div className="input-label">Latitude</div>
                <div className="input_field">
                  <input
                    type="text"
                    className="input"
                    name="extras"
                    disabled
                    placeholder="Latitude"
                    value={editGaonFormik.values.extras.lat}
                    onChange={(e) => {
                      editGaonFormik.setFieldValue(
                        "extras.lat",
                        Number(e.target.value)
                      );
                    }}
                  />
                </div>
              </div>
              <div className="input-box">
                <div className="input-label">Sub District</div>
                <div className="input_field">
                  <input
                    disabled
                    className="input"
                    type="text"
                    placeholder="Enter Subdistrict"
                    name="block"
                    value={editGaonFormik.valuesblock}
                    onChange={(e) => {
                      editGaonFormik.setFieldValue("block", e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="input-box">
                <div className="input-label">State</div>
                <div className="input_field">
                  <input
                    disabled
                    className="input"
                    type="text"
                    placeholder="Enter state"
                    name="state"
                    value={editGaonFormik.state}
                    onChange={(e) => {
                      editGaonFormik.setFieldValue("state", e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="input-box">
                  <div className="input-label">Grampanchayats</div>
                  <div className="input_field">
                    <input
                      type="text"
                      className="input"
                      name="grampanchayats"
                      placeholder="Enter grampanchayats"
                      value={editGaonFormik.values?.demography?.grampanchayats}
                      onChange={(e) => {
                        editGaonFormik.setFieldValue(
                          "demography.grampanchayats",
                          e.target.value
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="input-box">
                <div className="input-label">Literacy Rate</div>
                <div className="input_field">
                  <input
                    type="text"
                    className="input"
                    name="literacy"
                    placeholder="Enter Literacy"
                    value={editGaonFormik.values?.demography?.literacy}
                    onChange={(e) => {
                      editGaonFormik.setFieldValue(
                        "demography.literacy",
                        e.target.value
                      );
                    }}
                  />
                </div>
              </div>
              <div className="input-box">
                <div className="input-label">River Details</div>
                <div className="input_field">
                  <input
                    type="text"
                    className="input"
                    name="rivers"
                    placeholder="Enter River"
                    value={editGaonFormik.values?.geographicalInfo?.rivers}
                    onChange={(e) => {
                      editGaonFormik.setFieldValue(
                        "geographicalInfo.rivers",
                        e.target.value
                      );
                    }}
                  />
                </div>
              </div>
              <div className="input-box">
                <div className="input-label">Agriculture Area</div>
                <div className="input_field">
                  <input
                    type="text"
                    className="input"
                    placeholder="Enter Agriculture area"
                    name="aggriculture"
                    value={
                      editGaonFormik.values?.geographicalInfo?.aggriculture
                    }
                    onChange={(e) => {
                      editGaonFormik.setFieldValue(
                        "geographicalInfo.aggriculture",
                        e.target.value
                      );
                    }}
                  />
                </div>
              </div>
              <div className="input-box">
                <div className="input-label">Total Area</div>
                <div className="input_field">
                  <input
                    type="text"
                    className="input"
                    placeholder="Enter Agriculture area"
                    name="totalArea"
                    value={editGaonFormik.values?.geographicalInfo?.totalArea}
                    onChange={(e) => {
                      editGaonFormik.setFieldValue(
                        "geographicalInfo.totalArea",
                        e.target.value
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="input-box">
              <div className="input-label">Description</div>
              <textarea
                className="w-100"
                rows={9}
                cols={20}
                name="info"
                placeholder="Enter gaon info"
                value={editGaonFormik?.values?.info}
                onChange={(e) => {
                  editGaonFormik.setFieldValue("info", e.target.value);
                }}
              >
                {editGaonFormik?.values?.info}
              </textarea>
            </div>
          </div>

          <div className="row mt-5">
            {editGaonFormik.values.pic.length > 0 ? (
              editGaonFormik.values.pic.map((value, index) => {
                return (
                  <div className="col-lg-6 mt-1">
                    <div className="option-card" style={{ display: "flex" }}>
                      <ThumbnailPicker
                        width="60%"
                        height="250px"
                        onFileSelect={(file) => {
                          const _URL = window.URL || window.webkitURL;

                          const img = new Image();
                          uploadMediaImage(file, index);

                          img.src = _URL.createObjectURL(file);
                        }}
                        image={editGaonFormik.values.pic[index]}
                      />

                      <Icon
                        onClick={() => {
                          handelDeleteOption(index);
                        }}
                        color="danger"
                        size="10x"
                        icon="delete"
                      />
                    </div>
                  </div>
                );
              })
            ) : (
              <> </>
            )}
          </div>

          <div>
            <div>
              <div className="input-box ">
                <div className="flex_btn">
                  <button
                    type="button"
                    isLight
                    className="btn btn-success"
                    onClick={handleAddMoreOption}
                  >
                    {editGaonFormik.values.pic.length >= 1
                      ? "Add More Pic"
                      : "Add Pic"}
                  </button>{" "}
                  <button className="btn btn-success" type="submit">
                    Save
                  </button>{" "}
                  <button
                    className="btn btn-primary btn-dark"
                    type="button"
                    onClick={() => {
                      navigate("/gaon");
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditeGaon;
