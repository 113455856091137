import { requestWithToken } from "../helper/helper";

const getCategoryData = async (pageNo, pageSize) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/admin/categoryList?pageNo=${pageNo}&pageSize=${pageSize}`
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};
const getParentId = async () => {
  try {
    const response = await requestWithToken("GET", `/admin/categories`);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};
const addCategoryData = async (payload) => {
  try {
    const response = await requestWithToken("POST", `/admin/category`, payload);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};
const deleteCategoryData = async (id) => {
  try {
    const response = await requestWithToken("DELETE", `/admin/category/${id}`);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const getMSP = async (pageNo, pageSize) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/admin/mspList?pageNo=${pageNo}&pageSize=${pageSize}`
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const getMSPDetails = async (id) => {
  try {
    const response = await requestWithToken("GET", `/admin/msp/product/${id}`);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const createMSP = async (payload) => {
  try {
    const response = await requestWithToken("POST", `/admin/msp`, payload);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const editMSP = async (id, payload) => {
  try {
    const response = await requestWithToken("PUT", `/admin/msp/${id}`, payload);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const deleteMSP = async (id) => {
  try {
    const response = await requestWithToken("DELETE", `/admin/msp/${id}`);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const getProductList = async (pageNo, pageSize) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/admin/category/list?pageNo=${pageNo}&pageSize=${pageSize}`
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export {
  getCategoryData,
  addCategoryData,
  deleteCategoryData,
  getParentId,
  getMSP,
  getMSPDetails,
  createMSP,
  editMSP,
  deleteMSP,
  getProductList,
};
