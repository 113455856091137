import React, { useState, useEffect } from "react";
import plusWhite from "../../../assets/images/plus-white.svg";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../../styles/style.css";
import "../../../styles/payout.css";
import "../../../styles/upi-mandate.css";
import { TabPanel, Tabs } from "react-tabs";
import trashIcon from "../../../assets/images/trash-03.svg";
import editIcon from "../../../assets/images/edit_btn.svg";

import Breadcrumb from "../../../Breadcrumb/Breadcrumb";
import { Loader } from "../../loader";
import {
  fetchModuleDetails,
  fetchParticipantList,
  fetchWinners,
  postWinner,
} from "../../../action/contest";
import { Link, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";

const ContestList = () => {
  const { moduleId } = useParams();
  const [categoryList, setCategoryList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isListLoading, setIsListLoading] = useState(true);
  const [isWinnerSelected, setIsWinnerSelected] = useState(true);

  const [showModel, setShowModel] = useState(false);
  const [list, setList] = useState([]);
  const getCategoryPage = async () => {
    try {
      setIsLoading(true);
      const response = await fetchModuleDetails(moduleId); // Adjust 10 to the desired number of items per page
      if (response.status) {
        setCategoryList(response.data[0].contests);
        setIsWinnerSelected(response.data[0].winnersSelected);
        if (response.data[0].winnersSelected) {
          getWinners();
        } else {
          getUsers();
        }
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Failed to fetch category data", error);
    }
  };

  const getUsers = async () => {
    try {
      setIsListLoading(true);
      const response = await fetchParticipantList(moduleId);
      if (response.status) {
        if (response.data) {
          setList(response.data?.users);
        }
        setIsListLoading(false);
      }
    } catch (error) {
      console.error("Failed to fetch category data", error);
    }
  };

  const selectWinners = async () => {
    try {
      setIsListLoading(true);
      const response = await postWinner(moduleId);
      if (response.status) {
        setList(response?.data);
        setIsListLoading(false);
      }
    } catch (error) {
      console.error("Failed to fetch category data", error);
    }
  };

  const getWinners = async () => {
    try {
      setIsListLoading(true);
      const response = await fetchWinners(moduleId);
      if (response.status) {
        setList(response?.data?.list);
        console.log(" list", response?.data?.list);
        setIsListLoading(false);
      }
    } catch (error) {
      console.error("Failed to fetch category data", error);
    }
  };

  useEffect(() => {
    getCategoryPage();
  }, []);

  return isLoading ? (
    <Loader />
  ) : (
    <div className="payout_data-content" >
      <div className="payout_data-head">
        <div className="payout_data-head-top mb-2">
          <div className="payout_data-left">
            <div className="payout_data_ttl">
              <Breadcrumb
                paths={[
                  { name: "Contest", link: "/module/list" },
                  { name: "Question", link: "/contest/list" },
                ]}
              />
            </div>
          </div>
          <Button
            color="info"
            tag="a"
            onClick={() => {
              setShowModel(true);
            }}
          >
            Participants List
          </Button>
          <Link className="btn" color="info" to={`/contest/add/${moduleId}`}>
            Add Question
            <img src={plusWhite} alt="" className="payout_down" />
          </Link>
        </div>
      </div>
      <div className="tab_box">
        <Tabs>
          <TabPanel>
            <div className="">
              <table className="payout_table upi-mandate_table">
                <thead>
                  <tr className="tableHead">
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Title</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Options</div>
                    </th>

                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Action</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td colSpan="15">
                        <Loader />
                      </td>
                    </tr>
                  ) : (
                    <>
                      {categoryList?.length > 0 ? (
                        categoryList?.map((item) => (
                          <tr key={item._id}>
                            <td>{item?.title?.en}</td>
                            <td>{item?.options?.length}</td>
                            <td className="flex_btn three_btn">
                              <Link to={`/contest/edit/${item._id}`}>
                                <img src={editIcon} />
                              </Link>
                              <img src={trashIcon} />
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="15" className="text-center">
                            No data available
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </TabPanel>
        </Tabs>
      </div>
      <Offcanvas
        show={showModel}
        onHide={() => {
          setShowModel(false);
        }}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {isWinnerSelected ? "Winners" : "Participants"}
            <Button
              color="info"
              tag="a"
              onClick={() => {
                selectWinners();
              }}
              disabled={isWinnerSelected == true || !list.length}
            >
              Select Winners
            </Button>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="scroll_v">
            <table className="payout_table upi-mandate_table">
              <thead>
                <tr className="tableHead">
                  <th className="thHeader">
                    <div className="payoutHead hndIcon">Name</div>
                  </th>
                </tr>
              </thead>

              <tbody>
                {isListLoading ? (
                  <tr>
                    <td colSpan="4">
                      <Loader />
                    </td>
                  </tr>
                ) : (
                  <>
                    {list?.length > 0 ? (
                      list?.map((item) => (
                        <tr key={item?._id}>
                          <td>{item?.name}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3">No data available</td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default ContestList;
